<template>
  <div class="main-page">
    <template v-if="showHeader">
      <section class="page-section mb-3">
        <div class="container">
          <div class="grid justify-content-between">
            <div class="col-12 md:col-12">
              <div class="">
                <div class="flex align-items-center">
                  <div class="ml-2">
                    <div class="text-xl text-primary font-bold">
                      {{ $t("download_application_form") }}
                    </div>
                    <Divider />
                    <div class="text-purple-500">
                      <div class="font-bold text-yellow-500 mb-2">
                        {{ $t("instructions") }}
                      </div>
                      <ul>
                        <li>{{ $t("all_fields_marked_are_mandatory") }}</li>
                        <li>
                          {{
                            $t(
                              "names_provided_should_be_as_seen_on_your_national_id"
                            )
                          }}
                        </li>
                        <li>
                          {{
                            $t(
                              "after_downloading_and_completing_form_submit_to_school"
                            )
                          }}
                        </li>
                        <li>
                          {{
                            $t("kindly_attach_serial_number_pin_to_document")
                          }}
                        </li>
                        <li>
                          {{
                            $t(
                              "visit_this_website_for_more_information_on_graduate_school"
                            )
                          }}
                          <a target="_blank" href="https://gs.gctu.edu.gh/">
                            <Button :label="$t('gs.gctu.edu.gh')" class="p-button-sm p-button-link text-black px-0"
                              icon="pi pi-link"></Button>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
    <section class="page-section">
      <div class="container">
        <div class="grid">
          <div class="col-12 md:col-12 sm:col-12 comp-grid">
            <div class="">
              <div>
                <template v-if="!loading">
                  <div class="grid">
                    <div class="col-12">
                      <form ref="observer" tag="form" @submit.prevent="startRecordUpdate()">
                        <div class="card nice-shadow-5">
                          <div class="mb-4 text-blue-500 font-bold text-md">
                            {{ $t("personal_information") }}
                          </div>
                          <!--[form-content-start]-->
                          <div class="grid">
                            <div class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("title") }} *
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <Dropdown class="w-full" :class="getErrorClass('title')" optionLabel="label"
                                  optionValue="value" ref="ctrltitle" v-model="formData.title" :options="applicantTitles"
                                  :label="$t('title')" :placeholder="$t('select')" @change="validateGenderAndTitle">
                                </Dropdown>
                                <!-- </div> -->
                              </div>
                            </div>
                            <div v-if="formData.title == other" class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("specify_other") }} {{ $t("title") }} *
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <InputText ref="ctrltitle_other" v-model.trim="formData.title_other" :label="$t('title')"
                                  type="text" :placeholder="$t('title')"  class="w-full"
                                  :class="getErrorClass('title_other')">
                                </InputText>
                                <!-- </div> -->
                              </div>
                            </div>
                            <div class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("first_name") }} *
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <InputText ref="ctrlfirstname" v-model.trim="formData.firstname" :label="$t('first_name')"
                                  type="text" :placeholder="$t('first_name')"  class="w-full"
                                  :class="getErrorClass('firstname')">
                                </InputText>
                                <!-- </div> -->
                              </div>
                            </div>
                            <div class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("last_name") }} *
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <InputText ref="ctrllastname" v-model.trim="formData.lastname" :label="$t('last_name')"
                                  type="text" :placeholder="$t('last_name')"  class="w-full"
                                  :class="getErrorClass('lastname')">
                                </InputText>
                                <!-- </div> -->
                              </div>
                            </div>
                            <div class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("other_names") }}
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <InputText ref="ctrlothernames" v-model.trim="formData.othernames"
                                  :label="$t('other_names')" type="text" :placeholder="$t('other_names')"
                                   class="w-full" :class="getErrorClass('othernames')">
                                </InputText>
                                <!-- </div> -->
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="card nice-shadow-5">
                          <div class="mb-1 text-blue-500 font-bold text-md">
                            {{ $t("primary_contact") }}
                          </div>
                          <div class="mb-3 text-pink-400 text-sm">
                            {{ $t("please_provide_a_valid_phone_number") }}
                          </div>

                          <div class="grid">
                            <div class="col-12 md:col-4">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("dial_code") }} *
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <Dropdown class="w-full" :class="getErrorClass('dial_code')" optionLabel="dial_code"
                                  optionValue="value" ref="ctrldial_code" :filterFields="['name', 'dial_code']"
                                  :filter="true" v-model="formData.dial_code" :options="$menus.dial_codes_list"
                                  :label="$t('dial_code')" :placeholder="$t('select')">
                                  <template #option="slotProps">
                                    <div class="flex align-items-center">
                                      <div>
                                        <div>
                                          {{ slotProps.option.name }}
                                        </div>
                                        <div v-if="slotProps.option.name" class="text-sm text-500">
                                          {{ slotProps.option.code }}
                                          {{ slotProps.option.dial_code }}
                                        </div>
                                      </div>
                                    </div>
                                  </template>
                                </Dropdown>
                                <!-- </div> -->
                              </div>
                            </div>
                            <div class="col-12 md:col-8">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("primary_telephone") }} *
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <InputText ref="ctrlprimary_telephone" v-model.trim="formData.primary_telephone"
                                  :label="$t('primary_telephone')" type="text" :placeholder="$t('primary_telephone')"
                                  pattern="^[0-9]+$" class="w-full" maxlength="10" minlength="8"
                                  :class="getErrorClass('primary_telephone')" v-tooltip.top.focus="'Eg: 0209013836'">
                                </InputText>
                                <!-- </div> -->
                              </div>
                            </div>
                            <div class="col-12 md:col-12">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("email_address") }} *
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <InputText ref="ctrlemail_address" v-model.trim="formData.email_address"
                                  :label="$t('email_address')" type="email" :placeholder="$t('email_address')"
                                  class="w-full" maxlength="40" :class="getErrorClass('email_address')">
                                </InputText>
                                <!-- </div> -->
                              </div>
                            </div>
                          </div>
                        </div>

                        <!--[form-content-end]-->
                        <div v-if="showSubmitButton" class="my-3">
                          <div class="mb-4">
                            <Dropdown class="w-full" :class="getErrorClass('formtype')" optionLabel="label"
                              optionValue="value" ref="ctrlformtype" v-model="formData.formtype"
                              :options="$menus.downloadformTypeItems" :label="$t('formtype')" :placeholder="$t('Select Entry Level...')">
                            </Dropdown>
                          </div>

                          <div class="text-center">
                            <Button type="submit" :label="$t('download_application_form')" icon="pi pi-send"
                              :loading="saving" />
                          </div>
                        </div>
                      </form>
                      <slot :submit="submit" :saving="saving"></slot>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="p-3 text-center">
                    <ProgressSpinner style="width: 50px; height: 50px" />
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { PageMixin } from "../../mixins/page.js";
import { ApplicantEditPageMixin } from "../../mixins/applicanteditpage.js";
import { mapActions, mapGetters, mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import { ApiService } from "../../services/api";
import {
  required,
  requiredIf,
  email,
  sameAs,
  minLength,
  maxLength,
  minValue,
  maxValue,
  numeric,
  integer,
  decimal,
  ipAddress,
} from "@vuelidate/validators";
import { i18n } from "../../services/i18n.js";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: "downloadGraduateFormPage",
  components: {},
  mixins: [PageMixin, ApplicantEditPageMixin],
  props: {
    pageName: {
      type: String,
      default: "applicants",
    },
    idName: {
      type: String,
      default: "applicant_id",
    },
    routeName: {
      type: String,
      default: "downloadgraduateform",
    },
    pagePath: {
      type: String,
      default: "applicants/downloadgraduateform",
    },
    apiPath: {
      type: String,
      default: "applicants/downloadgraduateform",
    },
    pageIndex: { type: Number, default: 1 },
  },
  data() {
    return {
      formData: {
        title: "",
        title_other: "",
        fullname: "",
        firstname: "",
        lastname: "",
        othernames: "",
        dial_code: "",
        primary_telephone: "",
        email_address: "",
        formtype: "",
      },
      no: "No",
      yes: "Yes",
      na: "N/A",
      other: "Other",
      submitted: false,
      verifying: false,
      leapYears: [
        1904, 1908, 1912, 1916, 1920, 1924, 1928, 1932, 1936, 1940, 1944, 1948,
        1952, 1956, 1960, 1964, 1968, 1972, 1976, 1980, 1984, 1988, 1992, 1996,
        2000, 2004, 2008, 2012, 2016, 2020, 2024,
      ],
      monthsWith30days: ["September", "April", "June", "November"],
      applicantTitles: [],
      maleTitles: ["Mr", "Master"],
      femaleTitles: ["Miss", "Mrs", "Reverend Sister"],
    };
  },
  computed: {
    ...mapGetters("applicants", ["bioDataRecord"]),
    pageTitle: {
      get: function () {
        return this.$t("edit_applicants");
      },
    },
    apiUrl: function () {
      if (this.id) {
        return this.apiPath + "/" + encodeURIComponent(this.id);
      }
      return this.apiPath;
    },
  },
  validations() {
    let formData = {
      title: { required },
      title_other: {
        required: requiredIf(() => {
          const esit = this.formData.title == this.other;
          return esit;
        }),
      },
      fullname: {},
      firstname: { required },
      lastname: { required },
      othernames: {},
      dial_code: { required },
      primary_telephone: { required },
      email_address: { required, email },
      formtype: { required },
    };
    return { formData };
  },
  methods: {
    ...mapActions("applicants", ["updateBioData", "fetchBioData"]),
    load: function () {
      var url = this.apiUrl;
      this.loading = true;
      this.ready = false; // hide other components until main page is ready
      this.fetchBioData(url).then(
        (response) => {
          this.loading = false;
          this.ready = true;
          // this.bioDataRecord = response.data;
          this.formData = response.data;
          // Add other specified to Titles Dropdown
          let find_title = this.applicantTitles.find(
            (el) => el.value === this.formData.title
          );
          if (!find_title) {
            this.applicantTitles.push({
              value: this.formData.title,
              label: this.formData.title,
            });
          }
          this.updateFormFields();
        },
        (response) => {
          this.resetForm();
          this.loading = false;
          this.showPageRequestError(response);
        }
      );
    },
    async startRecordUpdate() {
      this.submitted = true;
      const isFormValid = await this.v$.$validate();
      if (!isFormValid) {
        this.flashMsg(
          this.$t("please_complete_the_form"),
          this.$t("form_is_invalid"),
          "error"
        );
        return;
      }
      this.saving = true;
      let id = this.id;
      let url = this.apiUrl;
      let payload = this.normalizedFormData();
      if (this.formData.title_other) {
        payload.title = this.formData.title_other;
      }
      let data = { id, url, payload };
      this.updateBioData(data).then(
        (response) => {
          this.saving = false;
          this.flashMsg(
            this.$t("information_was_updated"),
            this.$t("form_saved_successfully")
          );
          this.closeDialogs(); // close page dialog that if opened
          // Download Form
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = response.data;
          link.setAttribute("target", "_blank")
          link.setAttribute("download", `GCTU_Graduate_Admission_Form_${this.formData.formtype}.pdf`);
          document.body.appendChild(link);
          link.click();
          link.remove();
        },
        (response) => {
          this.saving = false;
          this.showPageRequestError(response);
        }
      );
    },
    updateFormFields: function () {
      //update form fields value after load from api
      //e.g convert fieldvalue (value,value2,value2) to array
    },
    resetForm() {
      //reset form fields value
      this.formData = {};
      //raise event to reset other custom form components
      //this.$EventBus.$emit("resetForm");
    },
    getErrorClass(field) {
      if (this.v$.formData[field].$invalid && this.submitted) {
        return "p-invalid";
      }
      return "";
    },
  },
  watch: {},
  async mounted() {
    this.applicantTitles = this.$menus.title_list;
    this.load();
  },
  async created() { },
};
</script>
<style scoped>
li {
  margin-top: 0.5em;
  line-height: 1.4em;
}

.p-inputgroup-addon {
  min-width: auto !important;
  padding: 0;
}
</style>
